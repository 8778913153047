<script setup lang="ts">
  const props = defineProps<{
    items: [{
      title: string;
      url: string;
      img: {
        node: {
          mediaItemUrl: string
        }
      },
      icon: {
        node: {
          mediaItemUrl: string
        }
      },
    }],
  }>();
</script>

<template>
  <div class="container-xl mw-100">
    <div class="row">
      <template v-for="item in props.items">
        <div class="col-lg-4 p-0">
          <div class="position-relative w-100 h-100">
            <a :href="item.url" class="w-100 h-100 d-block with-hover">
              <NuxtImg quality="80"
                       format="webp"
                       loading="lazy"
                       class="w-100 card-link-img"
                       :alt="`${item.title} Section`"
                       :src="item?.img?.node?.mediaItemUrl"/>
              <div class="position-absolute top-0 start-0 translate-middle card-title pt-5 ps-md-5">
                <h2 class="position-absolute text-white">
                  <span class="d-block category-label text-nowrap">{{ item.title }}</span>
                  <NuxtImg height="30" width="30" quality="80"
                           class="card-link-arrow hover-head-shake animated infinite"
                           src="/arrow-white.svg" :alt="`View Homes - ${item.title} Section`" loading="lazy"/>
                </h2>
              </div>
              <div class="position-absolute top-100 start-0 translate-middle pb-5 ps-5 section-item-icon"
                   v-if="item?.icon?.node?.mediaItemUrl">
                <NuxtImg class="position-absolute bottom-0"
                         height="30" width="30"
                         :src="item.icon?.node?.mediaItemUrl"
                         :alt="`${item.title} Icon`" format="webp"
                         loading="lazy"/>
              </div>
            </a>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<style scoped lang="css">
.card-title {
  padding-left: 1.4rem !important;
}
</style>