<script setup lang="ts">
  const props = defineProps<{
    items: [{
      headlineTitle: string;
      headlineDescription: string;
      sectionGroup: object[];
    }],
    isContentHtml?: boolean
  }>();
</script>

<template>
  <div class="w-100" v-for="(item, k) in props.items">
    <Headline :title="item.headlineTitle" :content="item.headlineDescription" :bg-white="k % 2 !== 0" isContentHtml/>
    <CardLinks :items="item.sectionGroup"/>
  </div>
</template>
